main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack elements vertically */
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars */
  position: relative; /* Ensure child elements are positioned relative to the main container */
}

h1 {
  font-family: "Helvetica Neue";
  font-size: 55px;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #121217;
}

h2 {
  font-family: "Helvetica Neue";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: .04em;
  color: #121217;
}
p {
  font-size: 16px;
}
a {
  color: #121217;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: .2em;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
ul {
  padding: 0;
  width: 100%;
  text-align: center;
}

a:hover {
  border-bottom: 1px solid #121217;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}

h1, ul {
  text-align: center; /* Ensure text is centered */
  position: relative; /* Ensure text is above the Vanta.js background */
  z-index: 1; /* Set a higher z-index to make the text clickable */
}

h1, a {
  color: white; /* Set font color to white for David Alson and LinkedIn */
}
